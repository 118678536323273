const breadcrumbMenu = document.getElementById("breadcrumbDropdown");

if (breadcrumbMenu) {
    document.addEventListener('click', (event1) => {
        if (!event1.target.matches('.breadcrumb-toggle')) {
            if (breadcrumbMenu.children[0].classList.contains('active')) {
                breadcrumbMenu.children[0].classList.remove('active')
            }
        }
    });

    breadcrumbMenu.addEventListener('click', (event) => {
        event.stopPropagation();
        breadcrumbMenu.children[0].classList.toggle("active");
    });
}